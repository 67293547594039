import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        isLog : !!localStorage.getItem("token"),
        // baseURL : 'http://172.16.25.181:3999/',
        baseURL : 'https://server.bhlab.in/',
        token : localStorage.getItem("token"),
        user : JSON.parse(localStorage.getItem("user"))
    },
    mutations: {
        loginUser (state, payload) {
            state.isLoggedIn = true
            state.isLog = true
            state.token = payload.token;
            state.user = payload.user;
        },
        logoutUser (state) {
            state.isLoggedIn = false
            state.isLog = false
            state.token = null
            state.user = null
        }
    }
})