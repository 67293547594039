/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import store from './store';
import VueSocketIO from 'vue-socket.io'
import Notifications from 'vue-notification'

router.beforeEach((to, from, next) => {

  // check if the route requires authentication and user is not logged in
  if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
    // redirect to login page
    next({ name: 'login' })
    return
  }

  // if logged in redirect to dashboard
  if (to.path === '/login' && store.state.isLoggedIn) {
    next({ name: 'dashboard' })
    return
  }

    
  next()  
})

Vue.config.productionTip = false

// Vue.use(new VueSocketIO({
//   debug: false,
//   connection: store.state.baseURL,
//   vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_'
//   }
// }))

Vue.use(ArgonDashboard)
Vue.use(Notifications)



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
