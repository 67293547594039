<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="BH Lab"
      title="BH Lab"
    >
      <template slot="links">
        <!-- <sidebar-item :link="{name: 'Dashboard', icon: 'ni ni-tv-2 text-red', path: '/dashboard'}"/> -->
        <sidebar-item :link="{name: 'Profile', icon: 'fa fa-users text-primary', path: '/profile'}"/>
        <sidebar-item :link="{name: 'Publications', icon: 'ni ni-paper-diploma text-orange ', path: '/publication'}"/>
        <sidebar-item :link="{name: 'Add User', icon: 'ni ni-badge text-yellow', path: '/admin'}" v-if="user.priv === 'admin'"/>
        <sidebar-item :link="{name: 'Add Project', icon: 'ni ni ni ni ni-map-big text-green', path: '/projects'}"  v-if="user.priv === 'admin'"/>

        <!-- <hr class="my-3"> ----------------------------------------- 
        <sidebar-item :link="{name: 'Map', icon: 'ni ni-pin-3 text-orange', notification : 'Comming Soon', path: '/maps'}"/>
        <sidebar-item :link="{name: 'General Survey', icon: 'ni ni ni-bullet-list-67 text-blue', path: '/surveys'}"/>
        <sidebar-item :link="{name: 'Onetime Survey', icon: 'ni ni ni-bullet-list-67 text-red', path: '/onetime'}"/>
        <sidebar-item :link="{name: 'Corridors', icon: 'ni ni ni ni ni-map-big text-green', path: '/corridors'}"/>
        <div  v-on:click="clearNotification()">
        <sidebar-item :link="{name: 'Manage Users', icon: 'fa fa-users text-primary', path: '/users', notification : notification}" v-if="notification > 0"/>
        <sidebar-item :link="{name: 'Manage Users', icon: 'fa fa-users text-primary', path: '/users'}" v-else/>
        </div>
        <sidebar-item :link="{name: 'Manage Admin', icon: 'ni ni ni ni ni-badge text-yellow', path: '/admin' , }"/> --> 
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import store from '../store';


  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'green', //vue|blue|orange|green|red|primary,
        notification : 0,
        user : store.state.user
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      clearNotification(){
        this.notification = 0;
      }
    },
    mounted(){
    },
    sockets: {
        userData : function(data)
        {
          this.notification = data
        },
        newUser : function(data)
        {
          this.$notify({
                group: 'user-info',
                title: 'Important message',
                text: 'A new user, '+data.name+', is added. Please verify!',
                duration : 1000,
                type : 'Warning'
                
          });
          this.notification += 1;
        },
        newSurvey : function(data)
        {
          this.$notify({
                group: 'user-info',
                title: 'Important message',
                text: 'A new survey has been addedd!',
                duration : 1000,
                type : 'Warning'
                
          });
        }
    },

  };
</script>
<style lang="scss">
</style>
