import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'



Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'profile',
      component: DashboardLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },     
        {
          path: '/projects',
          name: 'manage projects',
          component: () => import(/* webpackChunkName: "demo" */ './views/Projects.vue')
        },
        {
          path: '/projects/:id',
          name: 'project',
          component: () => import(/* webpackChunkName: "demo" */ './views/ProjectView.vue'),
          props: true
        },
        {
          path: '/admin',
          name: 'manage web admin',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin.vue')
        },      
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },       
        {
          path: '/publication',
          name: 'publications',
          component: () => import(/* webpackChunkName: "demo" */ './views/Publications.vue')
        }
        ,       
        {
          path: '/edit_user/:id',
          name: 'edit user',
          component: () => import(/* webpackChunkName: "demo" */ './views/AdminEdit.vue'),
          props : true
        }
        
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        }
      ]
    }
  ]
})
