<template>
    <div class="Image-upload-wrapper Image-upload">
        <div id="croppie" ></div>
        <div id="upload-wrapper">
            <base-button type="success" class="" v-on:click="modalVisible=true" v-if="!modalVisible" size="sm"><i class="fa fa-camera"></i> Change Image</base-button>
            <div class="Image-modal" v-if="modalVisible">
                <input
                    type="file"
                    v-on:change="setUpFileUploader"
                    class="form-control mt-3"
                    ref = "img"
                >
                <base-button type="success" class="mt-3" v-on:click="uploadFile" size="sm"><i class="fa fa-upload"></i> Upload</base-button>
                <base-button type="warning" class="mt-3" v-on:click="resetFile" size="sm"><i class="fa fa-times"></i> Cancel</base-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Croppie from 'croppie';
    import axios from 'axios';
    import store from "../store";
    export default {
        name : 'ImageUpload',
        props: {
            imgUrl : {
                default : 'img/theme/default.png'
            },
            width : {
                default : 0
            }
        },
        mounted(){
            this.$on('imageUploaded', function(imageData){
                this.image = imageData;
                this.croppie.destroy();
                this.setUpCroppie(imageData);
            });
            this.image = this.imgUrl;
            this.setUpCroppie();
        },
        data(){
            return{
                baseURL : store.state.baseURL,  
                user : store.state.user,
                modalVisible : false,
                image : null,
                croppie: null,

            }
        },
        methods : {
            setUpCroppie() {
                try{
                    let el = document.getElementById('croppie');
                    this.croppie = new Croppie(el, {
                        viewport : { width : 200, height : 200, type : 'square' },
                        boundary : { width : (this.width - 15), height : 220 },
                        showZoomer : true,
                        enableOrientation : true
                    });
                    this.croppie.bind({
                        url : this.image,
                    })
                }
                catch(err){
                    console.log('<<------- Croppie Error -------->>')
                    console.log(err);
                    console.log('<<------- XXXXXXXXXXXXX -------->>');
                }
            },
            setUpFileUploader(e){
                let files = e.target.files || e.dataTransfer.files;
                if(!files.length)
                    return
                else
                this.createImage(files[0]);
            },
            createImage(file){
                var image = new Image();
                var reader = new FileReader();
                var vm = this;

                reader.onload = (e) => {
                    vm.image = e.target.result;
                    vm.$emit('imageUploaded', e.target.result)
                }

                reader.readAsDataURL(file);
            },
            uploadFile(){
                this.croppie.result({
                    type : 'canvas',
                    size : 'viewport',
                    type : 'base64',
                    format : 'jpeg',
                    quality: 1
                }).then(response => {
                    var file = this.dataURLtoFile(response,'hello.jpeg');


                    this.image = response
                    let formData = new FormData();
                    formData.append('image', file);

                    console.log(formData)

                    axios({                    
                        url: this.baseURL + "admin",
                        headers : {
                            'Content-Type': 'multipart/form-data',
                            authorization : store.state.token
                        },
                        method: "PATCH",
                        data : formData
                    })
                    .then(response => {
                        this.$notify({
                            group: 'notification',
                            title: 'Important message',
                            text: 'Successfully updated the image',
                            duration : 3000,
                            type : 'success'                        
                        });
                        location.reload()
                    })
                    .catch(e => {
                        this.$notify({
                            group: 'notification',
                            title: 'Important message',
                            text: 'Failed to upload data. Please check the data again!',
                            duration : 3000,
                            type : 'error'                        
                        });
                        console.log(e)
                    })
                })
            },
            resetFile(){
                this.modalVisible = false;
            },
            dataURLtoFile(dataurl, filename) { 
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), 
                    n = bstr.length, 
                    u8arr = new Uint8Array(n);
                    
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                
                return new File([u8arr], filename, {type:mime});
            }
        }
        
    }
</script>

<style lang="scss">
    .Image-upload {
        .Image-modal{
            border-top : 1px solid #f4f4f4;
            margin-top : 10px;
            h4 {
                margin-bottom : 20px;
            }
        }

        div#upload-wrapper {
            text-align : center;
        }    
    }
</style>